<template>
  <div class="goods-list">
    <Header></Header>
    <div class="item" v-for="(item, index) in list" :key="index">
      <div class="title">{{item.goodsName}}</div>
      <div class="spec">
        <span>规格：</span>
        <span v-for="(iitem, ii) in item.goodsSpecifications" :key="ii">{{iitem.specification}}：{{iitem.value}}-{{iitem.valueEnd}}{{iitem.unit}}</span>
      </div>
      <div class="price-info">
        <div class="amount">数量：{{item.number}}</div>
        <div class="price">价格：<span>{{item.price}}</span>元</div>
        <van-button type="info" size="mini" @click="handleGoodsDetails(item.id)">商品详情</van-button>
      </div>
    </div>
    <div class="bottom-ctl">
      <div class="trans">运费：{{orderInfo.freightPrice}}元</div>
      <div class="order-price">订单总价：<span>{{orderInfo.orderPrice}}</span>元</div>
    </div>
  </div>
</template>

<script>
import { orderDetails } from '@/api/goodsShare'
import Header from '@/components/Header.vue'
export default {
  name: 'OrderDetails',
  components: {
    Header
  },
  data() {
    return {
      // orderID: '',
      list: [],
      orderInfo: {}
    }
  },
  mounted() {
    // console.log(this.$route.query.oid)
    // this.orderID = this.$route.query.oid
    orderDetails({orderId: this.$route.query.oid}).then(res=>{
      this.list = res.data.data.orderGoods
      this.orderInfo = res.data.data.orderInfo
    })
  },
  methods: {
    handleGoodsDetails (gid) {
      this.$router.push({
        path: 'GoodsDetails',
        query: { gid }
      })
    }
  }
}
</script>

<style lang="less">
.goods-list {
  .item {
    padding: 1.2rem;
    border-bottom: 1px solid #f0f0f0;
    .title {
      font-size: 1rem;
      font-weight: bold;
    }
    .spec {
      font-size: .9rem;
      color: #869099;
      padding: .5rem 0;
    }
    .price-info {
      font-size: .9rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price > span {
        font-weight: bold;
        color: orangered;
      }
    }
  }
  .bottom-ctl {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .trans {
      font-size: .9rem;
    }
    .order-price {
      font-size: 1.1rem;
      font-weight: bold;
      margin: 0 1.2rem;
      span {
        color: orangered;
      }
    }
    span {
      font-weight: bold;
    }
  }
}
</style>
